define("super-rentals/services/map-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    geocode: Ember.inject.service(),
    map: Ember.inject.service(),

    init() {
      if (!this.cachedMaps) {
        Ember.set(this, 'cachedMaps', {});
      }

      this._super(...arguments);
    },

    async getMapElement(location) {
      let camelizedLocation = Ember.String.camelize(location);
      let element = this.cachedMaps[camelizedLocation];

      if (!element) {
        element = this._createMapElement();
        let geocodedLocation = await this.geocode.fetchCoordinates(location);
        this.map.createMap(element, geocodedLocation);
        this.cachedMaps[camelizedLocation] = element;
      }

      return element;
    },

    _createMapElement() {
      let element = document.createElement('div');
      element.className = 'map';
      return element;
    }

  });

  _exports.default = _default;
});