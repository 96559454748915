define("super-rentals/templates/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zNhxp6Bk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"jumbo\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"right tomster\"],[9],[10],[0,\"\\n    \"],[7,\"h2\"],[9],[0,\"Contact Us\"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n        Super Rentals Representatives would love to help you\"],[7,\"br\"],[9],[10],[0,\"\\n        choose a destination or answer any questions you may have.\\n    \"],[10],[0,\"\\n    \"],[7,\"address\"],[9],[0,\"\\n        Super Rentals HQ\\n        \"],[7,\"p\"],[9],[0,\"\\n            1212 Test Address Avenue\"],[7,\"br\"],[9],[10],[0,\"\\n            Testington, OR 97233\\n        \"],[10],[0,\"\\n        \"],[7,\"a\"],[11,\"href\",\"tel:503.555.1212\"],[9],[0,\"1 (503) 555-1212\"],[10],[7,\"br\"],[9],[10],[0,\"\\n        \"],[7,\"a\"],[11,\"href\",\"mailto:superrentalsrep@emberjs.com\"],[9],[0,\"superrentalsrep@emberjs.com\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"link-to\",[\"about\"],[[\"class\"],[\"button\"]],{\"statements\":[[0,\"    About\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "super-rentals/templates/contact.hbs"
    }
  });

  _exports.default = _default;
});