define("super-rentals/templates/rentals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cpK5JT6E",
    "block": "{\"symbols\":[\"filteredResults\",\"rentalUnit\"],\"statements\":[[7,\"div\"],[11,\"class\",\"jumbo\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"right tomster\"],[9],[10],[0,\"\\n    \"],[7,\"h2\"],[9],[0,\"Welcome!\"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"We hope you find exactly what you're looking for in a place to stay.\"],[10],[0,\"\\n\"],[4,\"link-to\",[\"about\"],[[\"class\"],[\"button\"]],{\"statements\":[[0,\"    About Us\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[4,\"list-filter\",null,[[\"filter\"],[[27,\"action\",[[22,0,[]],\"filterByCity\"],null]]],{\"statements\":[[7,\"ul\"],[11,\"class\",\"results\"],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[1,[27,\"rental-listing\",null,[[\"rental\"],[[22,2,[]]]]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "super-rentals/templates/rentals.hbs"
    }
  });

  _exports.default = _default;
});