define("super-rentals/templates/components/list-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C+vrN8Up",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[27,\"input\",null,[[\"value\",\"key-up\",\"class\",\"placeholder\"],[[22,0,[\"value\"]],[27,\"action\",[[22,0,[]],\"handleFilterEntry\"],null],\"light\",\"Filter By City\"]]],false],[0,\"\\n\"],[14,1,[[22,0,[\"results\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "super-rentals/templates/components/list-filter.hbs"
    }
  });

  _exports.default = _default;
});