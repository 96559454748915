define("super-rentals/templates/components/rental-listing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "za6K6owh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[7,\"article\"],[11,\"class\",\"listing\"],[9],[0,\"\\n    \"],[7,\"a\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"toggleImageSize\"],null]],[12,\"class\",[28,[\"image \",[27,\"if\",[[22,0,[\"isWide\"]],\"wide\"],null]]]],[11,\"role\",\"button\"],[9],[0,\" \"],[7,\"img\"],[12,\"src\",[22,0,[\"rental\",\"image\"]]],[11,\"alt\",\"\"],[9],[10],[0,\"\\n        \"],[7,\"small\"],[9],[0,\"View Larger\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"details\"],[9],[0,\"\\n        \"],[7,\"h3\"],[9],[1,[22,0,[\"rental\",\"title\"]],false],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"detail owner\"],[9],[0,\"\\n            \"],[7,\"span\"],[9],[0,\"Owner:\"],[10],[0,\" \"],[1,[22,0,[\"rental\",\"owner\"]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"detail type\"],[9],[0,\"\\n            \"],[7,\"span\"],[9],[0,\"Type:\"],[10],[0,\" \"],[1,[27,\"rental-property-type\",[[22,0,[\"rental\",\"category\"]]],null],false],[0,\" - \"],[1,[22,0,[\"rental\",\"category\"]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"detail location\"],[9],[0,\"\\n            \"],[7,\"span\"],[9],[0,\"Location:\"],[10],[0,\" \"],[1,[22,0,[\"rental\",\"city\"]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"detail bedrooms\"],[9],[0,\"\\n            \"],[7,\"span\"],[9],[0,\"Number of bedrooms:\"],[10],[0,\" \"],[1,[22,0,[\"rental\",\"bedrooms\"]],false],[0,\"\\n        \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n            \"],[1,[27,\"location-map\",null,[[\"location\"],[[22,0,[\"rental\",\"city\"]]]]],false],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "super-rentals/templates/components/rental-listing.hbs"
    }
  });

  _exports.default = _default;
});