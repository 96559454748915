define("super-rentals/helpers/rental-property-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rentalPropertyType = rentalPropertyType;
  _exports.default = void 0;
  const communityPropertyTypes = ['Condo', 'Townhouse', 'Apartment'];

  function rentalPropertyType([propertyType]) {
    if (communityPropertyTypes.includes(propertyType)) {
      return 'Community';
    }

    return 'Standalone';
  }

  var _default = Ember.Helper.helper(rentalPropertyType);

  _exports.default = _default;
});