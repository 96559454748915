define("super-rentals/templates/about", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+hljXrW6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"jumbo\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"right tomster\"],[9],[10],[0,\"\\n    \"],[7,\"h2\"],[9],[0,\"About Super Rentals\"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n        The Super Rentals website is a delightful project created to explore Ember.\\n        By building a property rental site, we can simultaneously imagine traveling\\n        AND building Ember applications.\\n    \"],[10],[0,\"\\n\"],[4,\"link-to\",[\"contact\"],[[\"class\"],[\"button\"]],{\"statements\":[[0,\"    Contact Us\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "super-rentals/templates/about.hbs"
    }
  });

  _exports.default = _default;
});