define("super-rentals/controllers/rentals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      filterByCity(param) {
        if (param !== '') {
          return this.store.query('rental', {
            city: param
          }).then(results => {
            return {
              query: param,
              results: results
            };
          });
        } else {
          return this.store.findAll('rental').then(results => {
            return {
              query: param,
              results: results
            };
          });
        }
      }

    }
  });

  _exports.default = _default;
});