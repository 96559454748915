define("super-rentals/components/location-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['map-container'],
    mapElement: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      this.mapElement.getMapElement(this.location).then(mapElement => {
        this.element.append(mapElement);
      });
    }

  });

  _exports.default = _default;
});