define("super-rentals/components/list-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['list-filter'],
    value: '',

    init() {
      this._super(...arguments);

      this.filter('').then(allResults => {
        this.set('results', allResults.results);
      });
    },

    actions: {
      handleFilterEntry() {
        let filterInputValue = this.value;
        let filterAction = this.filter;
        filterAction(filterInputValue).then(filterResults => {
          if (filterResults.query === this.value) {
            this.set('results', filterResults.results);
          }
        });
      }

    }
  });

  _exports.default = _default;
});